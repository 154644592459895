import React from 'react';
import { PageProps } from 'gatsby';
import Layout from '@/components/Layout';
import { PageContainer, PageHeader } from '@/components/elements';
import Seo from '@/components/Seo';
import { planItemsItems } from '@/data/priceplans';
import PricePlan from '@/components/PricePlan';
import ContentSection from '@/components/ContentSection';
import KeyPoints from '@/components/KeyPoints';
import IconKeyPointSafeSecure from '@/assets/svg/icon-safe-secure-white.svg';
import IconKeyPointUptime from '@/assets/svg/icon-uptime-white.svg';
import IconKeyPointOptimized from '@/assets/svg/icon-server-opitimized-white.svg';
import IconKeyPointSupport from '@/assets/svg/icon-support-white.svg';
import Faq from '@/components/Faq';
import { hostingFaqData } from '@/data/faq';

const Home: React.FC<PageProps> = () => (
  <Layout>
    <Seo title="Home | MyServerJapan" description="" lang="en" />
    <PageContainer>
      <PricePlan
        title="Shared Web Hosting"
        description="Our Shared Hosting plans are designed to provide you with the tools needed at affordable prices. Our plans are all upgradable, just click Get Started and begin the process!"
        items={planItemsItems}
        btnLabel="Get Started"
        footerText="*All prices include tax."
        //  popularLabel="Most Popular"
        //  fullwidth
      />
      <ContentSection title="Benefits" bgColor={`primary`}>
        <p>
          We utilize top-of-the-line servers in Japan and other parts of the
          world to power your websites. Each server is optimized for speed and
          limited to how many clients can operate on each. This powerful
          combination of resources, performance enhancements, and limited
          occupancy means faster page load times that help improve your SEO and
          your visitors experience!
        </p>
      </ContentSection>

      <KeyPoints
        //  title={'title here'}
        //  description={'text here'}
        //  iconColor={pageColor}
        items={[
          {
            title: `Safe & Secure`,
            text: `Our servers are constantly updated to make sure they are safe and secure!`,
            icon: <IconKeyPointSafeSecure />,
          },
          {
            title: `99.9% Up time`,
            text: `We are proud to say that our servers are up 99.9% of the time.`,
            icon: <IconKeyPointUptime />,
          },
          {
            title: `Optimized for Japan`,
            text: `Get the best speed and performance for your website in Japan and Asia.`,
            icon: <IconKeyPointOptimized />,
          },
          {
            title: `English Support`,
            text: `All are systems are in English & Japanese as standard.`,
            icon: <IconKeyPointSupport />,
          },
        ]}
      />
      <Faq
        title="FAQ"
        lang={`en`}
        items={hostingFaqData}
        description="Have a question or not sure about something?"
        bgColor={`primaryLighter`}
        fullwidth={false}
      />
    </PageContainer>
  </Layout>
);

export default Home;
